// Animations

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.fade-in {
	animation-name: fadeIn;
	animation-iteration-count: 1;
	animation-timing-function: ease-in-out;
	animation-duration: 500ms;
	animation-fill-mode: forwards;
}

.ma0 {
  margin: 0rem !important;
}

.mh0 {
  margin-left: 0rem !important;
  margin-right: 0rem !important;
}

.pa0 {
  padding: 0rem !important;
}

.no-ripple {
  --ripple-color: transparent;
}

// Border Accent
.bb-gray {
  border-bottom: 1px solid #556567;
}

.b-light-gray {
  border-color: #ACB8C3;
}

.b-yellow {
  border-color: var(--ion-color-secondary);
}

// Ion Item
.ion-item-minimal {
  --min-height: auto;
  --padding-start: 0px;
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ion-list-container {
  box-shadow: inset 0 0 0.3rem rgb(0 0 0 / 25%);
  z-index: 1;
  position: relative;
  height: 175px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0rem 0.8rem;
  ion-item {
    --background: transparent;
  }
}

// Colors

.light-blue {
  color: #ACB8C3;
}

// Display

.dn {
  display: none!important;
}
