// Font Family

@font-face {
	font-family: "Proxima Nova";
	src: url("/assets/fonts/Mark Simonson - Proxima Nova Alt Black-webfont.eot");
	src: url("/assets/fonts/Mark Simonson - Proxima Nova Alt Black-webfont.eot?#iefix") format("embedded-opentype"),
		url("/assets/fonts/Mark Simonson - Proxima Nova Alt Black-webfont.woff2") format("woff2"),
		url("/assets/fonts/Mark Simonson - Proxima Nova Alt Black-webfont.woff") format("woff"),
		url("/assets/fonts/Mark Simonson - Proxima Nova Alt Black-webfont.ttf") format("truetype"),
		url("/assets/fonts/Mark Simonson - Proxima Nova Alt Black-webfont.svg#museo_sans700") format("svg");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "Proxima Nova";
	src: url("/assets/fonts/Mark Simonson - Proxima Nova Alt Bold-webfont.eot");
	src: url("/assets/fonts/Mark Simonson - Proxima Nova Alt Bold-webfont.eot?#iefix") format("embedded-opentype"),
		url("/assets/fonts/Mark Simonson - Proxima Nova Alt Bold-webfont.woff2") format("woff2"),
		url("/assets/fonts/Mark Simonson - Proxima Nova Alt Bold-webfont.woff") format("woff"),
		url("/assets/fonts/Mark Simonson - Proxima Nova Alt Bold-webfont.ttf") format("truetype"),
		url("/assets/fonts/Mark Simonson - Proxima Nova Alt Bold-webfont.svg#museo_sans500") format("svg");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "Proxima Nova";
	src: url("/assets/fonts/Mark Simonson - Proxima Nova Semibold-web.eot");
	src: url("/assets/fonts/Mark Simonson - Proxima Nova Semibold-web.eot?#iefix") format("embedded-opentype"),
		url("/assets/fonts/Mark Simonson - Proxima Nova Semibold-web.woff2") format("woff2"),
		url("/assets/fonts/Mark Simonson - Proxima Nova Semibold-web.woff") format("woff"),
		url("/assets/fonts/Mark Simonson - Proxima Nova Semibold-web.ttf") format("truetype"),
		url("/assets/fonts/Mark Simonson - Proxima Nova Semibold-web.svg#museo_sans300") format("svg");
	font-weight: 300;
	font-style: normal;
}


@font-face {
	font-family: "Proxima Nova";
	src: url("/assets/fonts/Mark Simonson - Proxima Nova Alt Regular-webfont.eot");
	src: url("/assets/fonts/Mark Simonson - Proxima Nova Alt Regular-webfont.eot?#iefix") format("embedded-opentype"),
		url("/assets/fonts/Mark Simonson - Proxima Nova Alt Regular-webfont.woff") format("woff"),
		url("/assets/fonts/Mark Simonson - Proxima Nova Alt Regular-webfont.ttf") format("truetype"),
		url("/assets/fonts/Mark Simonson - Proxima Nova Alt Regular-webfont.svg#museo_sans100") format("svg");
	font-weight: 100;
	font-style: normal;
}

.proxima-nova {
	font-family: "Proxima Nova", "Helvetica Neue", sans-serif !important;
}

// Universal

h1, h2, h3, h4, h5, h6 {
  @extend .proxima-nova;
}

p {
  font-family: "Proxima Nova";
  font-weight: 100;
}

.card-content-ios p {
  font-size: 15px;
}

// Sizes

.fs-13 {
  font-size: 13px;
}

.fs-15 {
  font-size: 15px;
}

.fs-17 {
  font-size: 17px;
}

.fs-21 {
  font-size: 21px;
}

.fs-28 {
  font-size: 28px;
}

.fs-40 {
  font-size: 40px;
}

.fs-42 {
  font-size: 42px;
}

// Colors

.anyman-blue {
  color: var(--ion-color-primary);
}

.anyman-yellow {
  color: var(--ion-color-secondary);
}

.anyman-gray {
  color: #5F7081;
}

.anyman-yellow-bg {
  background-color: var(--ion-color-secondary);
}
