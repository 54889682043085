// Utility
.transitionAll450ms {
  transition: all 450ms ease;
}
.o-50 {
  opacity: 0.50;
}
.o-75 {
  opacity: 0.75;
}
.pointer-events-none {
  pointer-events: none;
}

// Login
.login-screen {
  min-height: 150px;
  color:#fff;
  --background: rgba(147, 149, 54, 1);
  --background: -moz-linear-gradient(0deg, rgba(147, 149, 54, 1) 0%, rgba(27, 85, 144, 1) 100%);
  --background: -webkit-linear-gradient(0deg, rgba(147, 149, 54, 1) 0%, rgba(27, 85, 144, 1) 100%);
  --background: linear-gradient(0deg, rgba(147, 149, 54, 1) 0%, rgba(27, 85, 144, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1b5590",endColorstr="#939536",GradientType=1);
  .login-content {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .login-logo {
      width: 150px;
      height: 150px;
    }
  }
}


// Header
.tabs-inner {
  position: initial !important;
  contain: none !important;
  height: 100vh !important;
}

ion-header {
  ion-toolbar {
    --background: #1f252b;
    color: #fff;
    --padding-start: 0;
    --padding-top: 0;
    --padding-bottom: 0;
    ion-title {
      flex-basis: 100%;
      text-align: left;
      padding-inline: 4.5rem;
    }
    ion-button {
      ion-icon {
        color:#acb8c3;
      }
      &.link-button {
        --color: #fff;
        font-size: 12px;
      }
    }
  }
  .start-btns {
    margin-inline-start: 0px;
  }
  .avatar-btn {
    position: relative;
    height: auto;
    padding: 0px;
    --padding-start: 0px;
    --padding-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    --border-radius: 0px !important;
    ion-avatar {
      height: 58px;
      width: 58px;
      margin: 0;
    }
  }
  &.yellow {
    ion-toolbar {
      --background: #F8F32B;
    }
  }
  &.blue {
    ion-toolbar {
      --background: #1c5690;
    }
  }
}


// Tabs
ion-tab-bar {

  position: relative;
  border: 0px;
  contain: none;
  min-height: 89px;
  clip-path: ellipse(100% 99% at bottom);
  background: rgb(33,39,46);
  background: -moz-linear-gradient(0deg, rgba(33,39,46,1) 0%, rgba(57,67,78,1) 100%);
  background: -webkit-linear-gradient(0deg, rgba(33,39,46,1) 0%, rgba(57,67,78,1) 100%);
  background: linear-gradient(0deg, rgba(33,39,46,1) 0%, rgba(57,67,78,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#21272e",endColorstr="#39434e",GradientType=1);
  ion-tab-button {
    background: transparent;
    color: #989ca1;
    font-family: "Proxima Nova";
    position: relative;
    ion-icon {
      color: #989ca1 !important;
      --ion-color-base: #989ca1 !important;
      font-size: 15px;
    }

    ion-icon {
      transition: all 350ms ease;
      filter: none;
      opacity: 0.5;
      -webkit-filter: grayscale(100%);
      -moz-filter:    grayscale(100%);
      -ms-filter:     grayscale(100%);
      -o-filter:      grayscale(100%);
    }

    &.tab-selected {
      background: #1F252B;
      color: #FFF;
      ion-icon {
        color: var(--ion-color-secondary) !important;
        --ion-color-base: var(--ion-color-secondary) !important;
        opacity: 1;
        -webkit-filter: grayscale(0%);
        -moz-filter:    grayscale(0%);
        -ms-filter:     grayscale(0%);
        -o-filter:      grayscale(0%);
        filter:         grayscale(0%);
      }
      .button-inner {
        display: block !important;
      }
    }
  }
}

// User Menu
.userMenu {
  @media screen and (max-width: 640px) {
    --width: 100%;
  }
  .toolbar-background {
    background: #1F252B;
  }
  ion-avatar {
    height: 116px;
    width: 116px;
    margin: 0;
  }
  .avatar-column {
    width: 116px;
  }
  ion-list-header {
    --background: #5F7081;
    display: flex;
    align-items: center;
    min-height: 35px;
  }
  ion-item {
    --background: transparent;
    border-bottom: 2px solid #475360;
    &.heading {
      font-size: 28px;
      padding-bottom: 0.5rem;
    }
  }
  ion-list {
    --background: transparent;
    background: transparent;
    ion-item {
      ion-label {
        font-family: "Proxima Nova" !important;
      }
      &:last-child {
        border-bottom: 0px;
      }
      font-weight: 500;
      font-size: 15px;
    }
  }

  .chevron-btn {
    --color: #ACB8C3;
    .button-native {
      background: transparent;
    }
  }
}

.item {
  .white.sc-ion-label-ios-h {
    color: #fff !important;
  }
}

// Settings Menu
.settingsMenu {
  @media screen and (max-width: 640px) {
    --width: 100%;
  }
  .toolbar-background {
    background: #1F252B;
  }
  ion-content {
    --background: #2F3740;
    ion-list-header {
      --background: #5F7081;
      display: flex;
      align-items: center;
      min-height: 35px;
    }
    ion-item {
      --background: transparent;
      border-bottom: 2px solid #475360;
      &.heading {
        font-size: 28px;
        padding-bottom: 0.5rem;
      }
    }
    ion-list {
      --background: transparent;
      background: transparent;
      ion-item {
        ion-label {
          font-family: "Proxima Nova" !important;
        }
        &:last-child {
          border-bottom: 0px;
        }
        font-weight: 500;
        font-size: 15px;
        color: white;
      }
    }
    ion-radio-group {
      ion-item {
        --padding-start: 0px;
        --inner-padding-end: 0px;
        border-bottom: 0px;
        ion-label, label {
          padding: 0rem 0.25rem;
          font-size: 14px !important;
          font-weight: 300;
          line-height: 1;
        }
      }
      ion-radio {
        --color: white;
        --color-checked: white;
      }
    }
    ion-toggle {
      --background-checked: 2px;
    }
  }
  .chevron-btn {
    --color: #5F7081;
    .button-native {
      background: transparent;
    }
  }
}

// Start
app-start {
  background: #1c5690 !important;
  ion-content {
    --background: #252b31 !important;
  }
  ion-refresher {
    background: #1c5690 !important;
    ion-spinner {
      color: #f8f32b !important;
    }
    .refresher-pulling-icon {
      color: #f8f32b !important;
    }
  }
}


// Hero
section.hero {
  min-height: 150px;
  color:#fff;
  background: rgba(147, 149, 54, 1);
  background: -moz-linear-gradient(0deg, rgba(147, 149, 54, 1) 0%, rgba(27, 85, 144, 1) 100%);
  background: -webkit-linear-gradient(0deg, rgba(147, 149, 54, 1) 0%, rgba(27, 85, 144, 1) 100%);
  background: linear-gradient(0deg, rgba(147, 149, 54, 1) 0%, rgba(27, 85, 144, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1b5590",endColorstr="#939536",GradientType=1);
  &.articles {
    min-height: fit-content;
  }
}

section.blue {
  min-height: 240px;
  color:#fff;
  background: rgb(47,55,64);
  background: -moz-linear-gradient(140deg, rgba(47,55,64,1) 50%, rgba(5,115,225,1) 100%);
  background: -webkit-linear-gradient(140deg, rgba(47,55,64,1) 50%, rgba(5,115,225,1) 100%);
  background: linear-gradient(140deg, rgba(47,55,64,1) 50%, rgba(5,115,225,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2f3740",endColorstr="#0573e1",GradientType=1);
}

section.dark {
  background-color: #2f3740;
}

// Dark Section
section.dark {
  background: #2F3740;
  color: #FFF;
}

// Article
article.gradient {
  min-height: 250px;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color:#fff;
  &:after {
    content: ' ' !important;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgb(47,55,64);
    background: -moz-linear-gradient(90deg, rgba(47,55,64,0.75) 0%, rgba(5,115,225,0.75) 100%);
    background: -webkit-linear-gradient(90deg, rgba(47,55,64,0.75) 0%, rgba(5,115,225,0.75) 100%);
    background: linear-gradient(90deg, rgba(47,55,64,0.75) 0%, rgba(5,115,225,0.75) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2f3740",endColorstr="#0573e1",GradientType=1);
  }
}

// Buttons
ion-button {
  @extend .proxima-nova;
  text-transform: initial;
  height: 40px;
  font-size: 15px;
  &.link {
    height: auto;
    padding: 0;
    --background: transparent;
  }
  &.round {
    --border-radius: 2rem !important;
    button {
      padding-left: 3rem;
    }
  }
  &.secondary {
    --background: var(--ion-color-secondary);
    color: #1f252b;
  }
  &.round-check-completed {
    --border-radius: 2rem !important;
    --background: transparent;
    color: #5F7081;
    .check-completed {
      font-size: 40px;
      color: #5F7081;
    }
    button {
      padding-left: 3rem;
    }
  }
  &.button-small-1 {
    font-size: 13px;
    height: 28px;
    --background:  var(--ion-color-primary);
    // border: 2px solid var(--ion-color-primary);
    border-radius: 10px !important;
    color: #fff;
    font-weight: 700;
  }
  &.button-small-2 {
    font-size: 13px;
    height: 28px;
    --background: transparent;
    border: 2px solid var(--ion-color-primary);
    border-radius: 10px !important;
    color: var(--ion-color-primary);
    font-weight: 700;
  }
  &.button-small-3 {
    font-size: 13px;
    height: 28px;
    --background: transparent;
    border: 1px solid var(--ion-color-primary);
    border-radius: 10px !important;
    color: var(--ion-color-primary);
    font-weight: 700;
    &.remove {
      --background: #F8F32B;
      border: 1px solid #F8F32B;
      color: var(--ion-color-dark);
    }
  }
  &.favorite {
    --background: var(--ion-color-primary);
    color: #fff;
  }
  &.button-large {
    height: 60px;
    font-size: 17px;
  }
  &.transparent.yellow {
    --background: transparent;
    --border: none;
    --box-shadow: none;
    color: var(--ion-color-secondary);
    height: 2rem;
    font-size: 21px;
    font-weight: bold;
    --background-activated: transparent;
    --background-focused: transparent;
    --background-hover: transparent;
  }
  &.transparent {
    --background: transparent;
    --border: none;
    --box-shadow: none;
    color: white;
    height: 2rem;
    font-size: 21px;
    font-weight: bold;
    &.icon {
      --padding-start: 0;
      --padding-end: 0;
      width: 40px;
      height: 30px;
      font-size: 1rem;
      --background-activated: transparent;
      --background-focused: transparent;
      --background-hover: transparent;
    }
  }
  &.wh-40 {
    width: 40px;
    height: 40px;
  }
  &.no-padding {
    padding: 0px;
    --padding-start: 0px;
    --padding-end: 0px;
    --padding-top: 0px;
    --padding-bottom: 0px;
  }
  &.white.modal-select {
    --background: white;
    --box-shadow: none;
    border: 2px solid var(--ion-color-primary);
    border-radius: 15px !important;
    overflow: hidden;
    position: relative;
    min-height: 60px;
    --color-activated: white !important;
    --color-focused: white !important;
    --color-hover: white !important;
    ion-label {
      color: var(--ion-color-primary);
      width: 100%;
      font-size: 17px;
    }
    .button-icon {
      position: absolute;
      top: 50%;
      right: 0rem;
      transform: translateY(-50%);
      font-size:1.75rem;
    }
    &:hover, &:active, &:focus {
      --background: var(--ion-color-primary);
      color: white !important;
      ion-label {
        color: white !important;
      }
    }
  }
  &.white-outline-transparent {
    --background: transparent;
    --box-shadow: none;
    --color-activated: white !important;
    --color-focused: white !important;
    --color-hover: white !important;
    border-radius: 2rem !important;
    border: 2px solid white;
    &:hover, &:active, &:focus {
      --background: transparent;
      border-radius: 2rem !important;
      color: white !important;
      ion-label {
        color: white !important;
      }
    }
  }
  &.full-black {
    --background: #1F252B;
    --box-shadow: none;
    --color-activated: white !important;
    --color-focused: white !important;
    --color-hover: white !important;
    --border-radius: 2rem !important;
    &:hover, &:active, &:focus {
      --background: transparent;
      --border-radius: 2rem !important;
      color: white !important;
      ion-label {
        color: white !important;
      }
    }
  }
}

.hero-button {
  background-color: #2F3740;
  padding: 1rem 18px;
  width: 100%;
  display: flex;
  align-items: center;
  & > ion-icon {
    width: 46px;
    height: 41px;
  }
}

// Input
ion-item.input-container {
  position: relative;
  --background: transparent;
  // Rounded transparent
  --padding-start: 5px;
  ion-label {
    --color: white !important;
    &.black {
      color: var(--ion-color-dark) !important;
    }
  }
  ion-input.round, ion-textarea.round, textarea.round {
    position: relative;
    border-radius: 10px !important;
    border: 1px solid #FFF  !important;
    opacity: 1 !important;
    overflow: hidden !important;
    input, textarea {
      padding-left: .75rem;
      color: white !important;
    }
    textarea {
      padding: .75rem;
    }
    input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: white !important;
    }
    input::-moz-placeholder { /* Firefox 19+ */
      color: white !important;
    }
    input:-ms-input-placeholder { /* IE 10+ */
      color: white !important;
    }
    input:-moz-placeholder { /* Firefox 18- */
      color: white !important;
    }
    &.black {
      border: 1px solid var(--ion-color-dark)  !important;
      input {
        color: var(--ion-color-dark) !important;
      }
      input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: var(--ion-color-dark) !important;
      }
      input::-moz-placeholder { /* Firefox 19+ */
        color: var(--ion-color-dark) !important;
      }
      input:-ms-input-placeholder { /* IE 10+ */
        color: var(--ion-color-dark) !important;
      }
      input:-moz-placeholder { /* Firefox 18- */
        color: var(--ion-color-dark) !important;
      }
    }
  }
}

textarea.round {
  background-color: var(--ion-color-dark);
  padding: .75rem;
  color: white !important;
}

input[type="date"] {
  color: inherit;
  background: transparent;
  border: 0px;
  text-align: center;
  width: 100%;
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: inherit !important;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: inherit !important;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: inherit !important;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: inherit !important;
  }
}

// Tooltip
.tooltip-btn {
  --padding-start: 0.25em;
  --padding-end: 0.25em;
  font-size: 0.75rem;
  height: auto;
  margin-top: 0px;
}

.amf-tooltip {
  .popover-content {
    padding: 1rem 1.25rem;
    background-color: var(--ion-color-primary);
    color: white;
    font-size: 1rem;
    font-weight: 500;
    box-shadow: rgb(50 50 93 / 25%) 0px 50px 100px -20px, rgb(0 0 0 / 30%) 0px 30px 60px -30px;
  }
}

.ion-item-input-tooltip {
  margin-top: 0.75rem;
  margin-bottom: 4px;
  ion-label {
    margin-bottom: 4px !important;
    --min-height: 0px !important;
    display: flex !important;
    justify-content: start !important;
    align-items: center !important;
  }
}

// Exercise Input

.workout-input {
  ion-label {
    display: flex;
    align-items: center;
    width: 75px !important;
  }
}

ion-input.square {
  position: relative;
  border-radius: 0px !important;
  border: 1px solid #FFF  !important;
  opacity: 1 !important;
  overflow: hidden !important;
  min-height: 60px !important;
  padding: 1rem;
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 1.5rem;
  margin: 0.5rem;
  input {
    padding-left: .75rem;
    color: white !important;
  }
  input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: white !important;
  }
  input::-moz-placeholder { /* Firefox 19+ */
    color: white !important;
  }
  input:-ms-input-placeholder { /* IE 10+ */
    color: white !important;
  }
  input:-moz-placeholder { /* Firefox 18- */
    color: white !important;
  }
  &.text {
    text-align: left;
    font-size: 1rem;
    min-height: 1rem !important;
    border: 1px solid #656565 !important;
    width: calc(100% - 0.5rem) !important;
    margin-top: 0.5rem;
    padding: 0 0.5rem !important;
  }
}

ion-button.square {
  border-radius: 0px !important;
  border: 2px solid #0573E1  !important;
  min-height: 60px !important;
  min-width: 60px !important;
  --background: transparent !important;
  --color: #0573E1;
  --color-activated: white;
  --color-focused: white;
  --color-hover: white;
}

// Native Select
ion-select-popover {
  ion-label {
    white-space: normal !important;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size:0.95rem;
  }
}

// Input error
.error {
  font-size: 0.90rem;
  color: maroon;
}

// Input hint
.hint {
  font-size: 0.90rem;
  color: gray;
}

// Modal
.anyman-modal {
  overflow: hidden;
  .sc-ion-modal-md {
    background-color: var(--ion-backdrop-color, #FFF);
  }
  @media screen and (min-width: 640px) {
    .modal-wrapper {
      max-width: 80%;
      max-height: 75vh;
      border-radius: 1rem;
      box-shadow: rgb(0 0 0 / 37%) 8px 8px 8px;
    }
  }
  .choice-row {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  ion-header ion-toolbar {
    background-color: #2F3740;
    min-height: 97px;
    display: flex;
    &.post {
      min-height: 58.5px;
    }
    ion-title {
      text-align: center;
      font-size: 15px;
    }
  }
  ion-radio-group {
    ion-item {
      border: 1px solid #ACB8C3;
      margin-left: 1rem;
      margin-right: 1rem;
      margin-bottom: 1rem;
      border-radius: 1rem;
      ion-label {
        @extend .proxima-nova;
        font-weight: 500;
      }
      &.item-radio-checked {
        border: 2px solid #0573e1;
        color: #0573e1;
      }
    }
    &.minimal {
      ion-item {
        border: 0px solid #ACB8C3;
        margin-left: 0rem;
        margin-right: 0rem;
        margin-bottom: 0rem;
        border-radius: 0rem;
        --padding-left: 0px;
        --padding-right: 0px;
        --inner-padding-start: 0px;
        --inner-padding-end: 0px;
        ion-label {
          @extend .proxima-nova;
          font-weight: 500;
        }
        &.item-radio-checked {
          border: 0px solid #0573e1;
          color: #0573e1;
        }
      }
    }
  }
  &.dark {
    ion-content {
      --background: #1F252B;
    }
    ion-header, ion-footer {
      background: #1f252b;
    }
    ion-header ion-toolbar {
      background-color: #2F3740;
      min-height: auto;
      display: flex;
      ion-title {
        text-align: center;
        font-size: 15px;
      }
    }
    &.half {
      ion-content {
        --background: #2F3740;
      }
    }
    &.timer {
      --width: 90%;
      --height: 70%;
      --max-height: 365px;
      --max-width: 350px;
      --border-radius: 8px;
      overflow: hidden;
      border-color: 1px solid #5F7081;
      .modal-shadow {
        box-shadow: rgb(0 0 0 / 25%) 0px 54px 55px, rgb(0 0 0 / 12%) 0px -12px 30px, rgb(0 0 0 / 12%) 0px 4px 6px, rgb(0 0 0 / 17%) 0px 12px 13px, rgb(0 0 0 / 9%) 0px -3px 5px
      }
    }
    &.small-modal {
      --width: 90%;
      --height: 70%;
      --max-height: 320px;
      --max-width: 350px;
      --border-radius: 8px;
      overflow: hidden;
      border-color: 1px solid #5F7081;
      .modal-shadow {
        box-shadow: rgb(0 0 0 / 25%) 0px 54px 55px, rgb(0 0 0 / 12%) 0px -12px 30px, rgb(0 0 0 / 12%) 0px 4px 6px, rgb(0 0 0 / 17%) 0px 12px 13px, rgb(0 0 0 / 9%) 0px -3px 5px
      }
    }
    &.session-complete {
      --width: 90%;
      --height: 70%;
      --max-height: 365px;
      --max-width: 350px;
      --border-radius: 8px;
      overflow: hidden;
      border-color: 1px solid #5F7081;
      .modal-shadow {
        box-shadow: rgb(0 0 0 / 25%) 0px 54px 55px, rgb(0 0 0 / 12%) 0px -12px 30px, rgb(0 0 0 / 12%) 0px 4px 6px, rgb(0 0 0 / 17%) 0px 12px 13px, rgb(0 0 0 / 9%) 0px -3px 5px
      }
    }
    &.subs {
      --max-height: 365px;
      --max-width: 350px;
      --border-radius: 8px;
      .modal-shadow {
        box-shadow: rgb(0 0 0 / 25%) 0px 54px 55px, rgb(0 0 0 / 12%) 0px -12px 30px, rgb(0 0 0 / 12%) 0px 4px 6px, rgb(0 0 0 / 17%) 0px 12px 13px, rgb(0 0 0 / 9%) 0px -3px 5px
      }
    }
  }
  &.mid {
    ion-content {
      --background: #2F3740;
    }
    ion-header ion-toolbar {
      background-color: #2F3740;
      min-height: auto;
      display: flex;
      ion-title {
        text-align: center;
        font-size: 15px;
      }
    }
  }
}

// Card
.anyman-card {
  box-shadow: none;
  &.short-image {
    img {
      height: 200px;
      object-fit: cover;
      object-position: center;
    }
  }
  .card-content-md p {
    font-size: 15px !important;
    font-weight: 100 !important;
  }
  .equipment-list, .schedule {
    span {
      font-weight: 500 !important;
    }
  }
}

.hero-article {
  &.short-image {
    img {
      height: 200px;
      object-fit: cover;
      object-position: center;
    }
  }
}

// Session

.todaySession {
  &.row.small-12 {
    margin-left: 0;
    margin-right: 0;
  }
  .head {
    background-color: #5F7081;
  }
  .workout-select.row {
    margin: 0;
    border: 0;
    background-color: transparent;
    .row.set {
      margin: 0;
    }
  }
}

// Avatar
ion-avatar {
  --border-radius: 0px;
}

// Week Day Selector
app-week {
  li {
    width: 14.29%;
  }
  a {
    @extend .proxima-nova;
    transition: all 250ms ease;
    font-weight: 100;
    font-size: 21px;
    height: 54px;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0.15);
    text-decoration: none;
    &:active, &:focus, &:hover, &.today {
      background-color: var(--ion-color-secondary);
      border: 1px solid #5F7081;
      color:#000;
      font-weight: 700;
    }
  }
}

// Comparison Images

.swiper-pagination-bullets {
  display: none !important;
}

.comparison-image-slide-nav {
  position: absolute;
  bottom:0px;
  left:0px;
  width:100%;
  z-index: 1;
  .comparison-image-chevron-button {
    padding:0.25rem;
    height: 25px;
  }
}

.comparison-image-date {
  height:33px;
  display: flex;
  justify-content: center;
  align-items: center;
}

// Selection Dropdown

.anyman-select {
  color: #000;
  --placeholder-color: #000;
  --placeholder-opacity: 0.55;
  border: 1px solid black;
  border-radius: 1rem;
  font-family: 'Proxima Nova';
  width: 100%;
  max-width: 100%;
  text-align: center;
  padding-right: 1rem;
}

.anyman-select-white {
  color: #fff;
  --placeholder-color: #fff;
  --placeholder-opacity: 0.55;
  border: 1px solid #fff;
  border-radius: 10px;
  font-family: 'Proxima Nova';
  width: 100%;
  max-width: 100%;
  padding-right: 1rem;
  padding-left: .75rem;
}

.anyman-select-dark {
  color: var(--ion-color-secondary);
  --placeholder-color: var(--ion-color-secondary);
  --placeholder-opacity: 1;
  border: 0px solid black;
  border-radius: 1rem;
  font-family: 'Proxima Nova';
  width: 100%;
  max-width: 100%;
  text-align: center;
  padding-right: 1rem;
  padding-left: 0rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.popover-content.sc-ion-popover-md {
  overflow: hidden;
  .popover-viewport.sc-ion-popover-md {
    overflow-y: auto;
    width: calc(100% + 1rem);
    padding-right: 1rem;
  }
}

// Iconography

.icon-weight {
  width: 17px;
  height: 18px;
}

.icon-waist {
  width: 25px;
  height:18px;
}

.icon-workout {
  width: 25px;
  height: 14px;
}

.icon-workout-lifting {
  width: 20px;
  height: 14px;
}

.icon-calendar {
  height: 20px;
  width: 20px;
}

// Ion Card
.ion-card-condensed {
  transition: all 500ms ease;
  position: relative;
  box-shadow: none;
  margin: 0px;
}

.workout-select-row {
  border-top: 1px solid #5f7081;
  &:last-child {
    border-bottom: 1px solid #5f7081;
  }
}

.workout-select-row-bottom {
  border-bottom: 1px solid #5f7081;
}

.column.left-border {
  border-left: 1px solid #5f7081;
}
.column.right-border {
  border-right: 1px solid #5f7081;
}

// Styled Dark Row
.workout-select.row, .dark-row.row {
  border-top: 1px solid #5f7081;
  border-bottom: 1px solid #5f7081;
  background-color: #1f252b;
  .column.left-border {
    border-left: 1px solid #5f7081;
  }
  .column.right-border {
    border-right: 1px solid #5f7081;
  }
  .sets {
    padding-left: 0;
    padding-right: 0;
    .row {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.border-dark-top-accent {
  border-bottom: 1px solid #5f7081;
}

.border-dark-bottom-accent {
  border-bottom: 1px solid #5f7081;
}

// Ion Search
.searchbar-input-container.sc-ion-searchbar-ios {
  height: 40px;
}

.searchbar-search-icon.sc-ion-searchbar-ios {
  left: 10px;
}

.ion-color.sc-ion-searchbar-ios-h .searchbar-input.sc-ion-searchbar-ios, .ionic-selectable-modal .searchbar-left-aligned.sc-ion-searchbar-ios-h .searchbar-input.sc-ion-searchbar-ios {
  background: var(--ion-light-blue);
  font-style: italic;
  padding-inline-start: 40px;
  --placeholder-color: #1f252b;
  --placeholder-opacity: 1;
  color: #1f252b;
}

.ionic-selectable-modal .sc-ion-searchbar-ios-h {
  --background: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.07);
  --border-radius: 10px;
  --box-shadow: none;
  --cancel-button-color: var(--ion-color-primary, #3880ff);
  --clear-button-color: var(--ion-color-step-600, #666666);
  --color: var(--ion-text-color, #000);
  --icon-color: var(--ion-color-step-600, #666666);
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
  height: 60px;
  contain: strict;
}

.ion-color.sc-ion-searchbar-ios-h .searchbar-search-icon.sc-ion-searchbar-ios, .ionic-selectable-modal .searchbar-search-icon.sc-ion-searchbar-ios {
  color: var(--ion-color-primary);
}

// Ion Accordion
.accordion {
  border-top: 1px solid #ACB8C3;
  button {
    background-color: transparent;
    padding: 5.5px 0;
    .category {
      padding-left: 18px;
    }
    ion-icon {
      transform: rotate(180deg);
      transition: transform 400ms linear;
      transform-origin: center;
    }
    &.collapse {
      ion-icon {
        transform: rotate(0deg);
      }
    }
  }
  ion-item {
    --padding-start: 37px;
    img {
      border: 1px solid #124A68;
      object-fit: cover;
    }
  }
  &:last-child {
    border-bottom: 1px solid #ACB8C3;
  }
}

.accordion-content {
  transition: all 400ms linear;
  height: 100%;
  padding: 0.25rem 0;
  overflow: hidden;
  &.collapse {
    height: 0;
    padding: 0;
  }
}

// Education article
ion-header.article, .article ion-toolbar {
  --min-height: 58px;
}


.copy {
  ul {
    margin-left: 0;
    padding-inline-start: 17px;
    font-family: "Proxima Nova";
    font-weight: 100;
  }
}

.heading-skeleton {
  ion-skeleton-text {
    line-height: 28px;
  }
}

.paragraph-skeleton {
  ion-skeleton-text {
    line-height: 13px;
  }
}

.image-skeleton {
  ion-skeleton-text {
    line-height: 200px;
    margin-top: 0;
  }
}

// Recipe Guide
ion-header.recipe-guide, .recipe-guide ion-toolbar {
  --min-height: 58px;
}

// Coming Soon page

ion-content.dark {
  --background: #2F3740;
  color: #FFF;
}

// Featured image
.featured-image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0.95;
}


// Featured image backdrop
.featured-image-backdrop {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0.15;
}

// Onboarding
app-onboarding {
  ion-content {
    --keyboard-offset: 0px !important;
  }
}

ion-item.no-padding {
  --inner-padding-end: 0px !important;
  .item-inner {
    padding-inline-start: 0px !important;
    padding-inline-end: 0px !important;
  }
}

// Date
.amf-date {
  position: relative;
  display: block;
  width: 100%;
  input {
    background-color: transparent !important;
    border-radius: 10px !important;
    border-color: white !important;
    margin: 0rem 0.25rem !important;
    min-height: 38px !important;
    font-size: 15px !important;
    padding: 0.25rem 0.75rem !important;
    border: 1px solid white;
    width: 100%;
    text-align: left;
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(100%);
  }
}

// Select
.amf-select {
  position: relative;
  display: block;
  &:after {
    content: "";
    display: inline-block !important;
    border-right: 3px solid white !important;
    border-bottom: 3px solid white !important;
    width: 7px;
    height: 7px;
    transform: rotate(-315deg) translateY(-50%);
    position: absolute;
    top: 1rem;
    right: 1.5rem;
  }
  .select-dropdown {
    position: relative !important;
    background-color: transparent !important;
    border-radius: 10px !important;
    border-color: white !important;
    margin: 0rem 0.25rem !important;
    min-height: 38px !important;
    font-size: 15px !important;
    padding: 0.25rem 0.75rem !important;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}

.image-modal-content {
  height: 100%;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.no-images {
  min-height: 150px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 3rem;
  color: #38424d;
}

.ui-disable-wrapper {

}

.pointer-events-none {
  pointer-events: none;
}

// Forum
ion-item {
  &.bg-light-blue {
    --background: #EAEDF0;
  }
  &.bg-dark {
    --background: #2F3740;
    label {
      color: #fff
    }
  }
  &.forum {
    h3 {
      color: #0573E1;
    }
    img {
      border-radius: 100%;
      width: 75px;
      height: 75px;
      object-fit: cover;
    }
    ion-button {
      ion-icon {
        --color:#acb8c3;
      }
    }
  }
  .voted_up {
    color: #0573E1;
    display: flex;
    align-items: center;
    ion-icon {
      width: 32px;
    }
  }
  .comments_count {
    color: #0573E1;
    display: flex;
    align-items: center;
    ion-icon {
      width: 18px;
      height: 16px;
    }
  }
}

.comments_voted_up {
  color: #0573E1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    ion-icon {
      width: 32px;
    }
}

.program {
  .voted_up {
    color: #0573E1;
    display: flex;
    align-items: center;
    ion-icon {
      width: 32px;
    }
  }
  .title-thread {
    width: calc(100% - 80px - 0.5rem);
  }
  .featured_image {
    border-radius: 100%;
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: 0.5rem;
    object-fit: cover;
  }
}

.comment {
  img {
    max-width: 128px;
    height: auto;
    border-radius: 10px;
    &.profile_img {
      border-radius: 100%;
      margin-right: 0.5rem;
      height: 32px;
      max-width: 32px;
      object-fit: cover;
    }
  }
  iframe {
    width: 100%;
    height: 200px;
  }
}

ion-card.comment {
  --background: #EAEDF0;
  border-radius: 10px;
  box-shadow: none;
}

ion-button.ion-color-report {
  --background: #ACB8C3;
  background: #ACB8C3;
  height: 24px;
  width: 24px;
  border-radius: 100%;
  --padding-top: 4px;
  --padding-bottom: 4px;
  --padding-start: 4px;
  --padding-end: 4px;
}

ion-button.ion-color-report.text {
  width: auto;
  border-radius: 0;
  --background: transparent;
  background: transparent;
  color: #5F7081;
  span {
    text-decoration: underline;
  }
  .container-icon {
    --background: #ACB8C3;
    background: #ACB8C3;
    height: 24px;
    width: 24px;
    border-radius: 100%;
    padding: 4px;
    margin-right: 4px;
  }
  ion-icon {
    --background: #ACB8C3;
    background: #ACB8C3;
  }
}

.hello-image {
  width: 180px;
  height: 180px;
}


ion-toolbar.threadPage {
  background-color: #2F3740;
  min-height: 97px;
  display: flex;
  &.post {
    min-height: 58.5px;
  }
  ion-title {
    text-align: center;
    font-size: 15px;
  }
}

ion-toolbar.programDetails {
  ion-title {
    text-align: center;
    font-size: 15px;
  }
}

// Adjust popover bug
// https://github.com/ionic-team/ionic-framework/issues/27599
[popover].popover-viewport {
  display: initial;
  position: initial;
  margin: initial;
  border: initial;
  background: initial;
  padding: initial;
  width: initial;
  height: initial;
  overflow: initial;
  inset: initial;
  color: initial;
}
